<script setup lang="ts">
import type { KeenSliderInstance, KeenSliderOptions } from "keen-slider";

const emit = defineEmits<{ (event: "toggleAsidebar"): void }>();

const { data: appInitData } = useAppInitData();
const isGuest = useIsGuest();

const bannerPromo = computed(() => appInitData.value?.popupPresetPackage);
const showBingoPromo = computed(() => !isGuest.value && !!appInitData.value?.bingo?.isActive);

const activeFeatures = computed(() =>
	[
		{ isActive: !!bannerPromo.value, component: "OHomeBannerPromotionItem", props: { offer: bannerPromo.value } },
		{ isActive: showBingoPromo.value, component: "OBingoSidebarWidget" }
	].filter((feature) => feature.isActive)
);

const interval = ref();
const sliderActiveSlide = ref(0);
const sliderAsidebarRef = ref();

const autoplay = (slider: KeenSliderInstance) => {
	clearInterval(interval.value);
	interval.value = setInterval(() => {
		if (slider.track.details) {
			slider.track.details.maxIdx === slider.track.details.abs ? slider.moveToIdx(0) : slider.next();
		}
	}, 5000);
};

const sliderOptions: KeenSliderOptions = {
	loop: false,
	mode: "snap",
	slides: { perView: 1 },
	created(slider) {
		autoplay(slider);
	},
	slideChanged(slider: KeenSliderInstance) {
		autoplay(slider);
		sliderActiveSlide.value = slider.track.details.abs;
	}
};

if (process.client) {
	watch(activeFeatures, () => {
		sliderAsidebarRef.value?.slider?.update();
	});
}

onBeforeUnmount(() => {
	clearInterval(interval.value);
});
</script>

<template>
	<div>
		<component
			:is="activeFeatures[0]?.component"
			v-if="activeFeatures?.length === 1"
			v-bind="activeFeatures[0]?.props"
		/>

		<ASlider v-else ref="sliderAsidebarRef" :options="sliderOptions">
			<div v-for="(feature, index) in activeFeatures" :key="index" class="keen-slider__slide">
				<component :is="feature?.component" v-bind="feature?.props" @toggle-sidebar="emit('toggleAsidebar')" />
			</div>

			<template #dots="{ slider }">
				<div class="pagination">
					<div
						v-for="(_, index) in activeFeatures?.length"
						:key="index"
						:class="['pagination-item', { 'is-active': sliderActiveSlide === index }]"
						@click="slider?.moveToIdx(index)"
					/>
				</div>
			</template>
		</ASlider>
	</div>
</template>

<style lang="scss" scoped>
.pagination {
	display: flex;
	justify-content: center;
	gap: 4px;
	margin-top: 8px;
	padding: 0 16px;

	&-item {
		flex-grow: 1;
		max-width: 32px;
		height: 4px;
		border-radius: 12px;
		background: var(--celaya);
		cursor: pointer;

		&.is-active {
			background: var(--сirebon);
		}
	}
}
</style>
